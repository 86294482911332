import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";
import { Observable, take } from "rxjs";
import { JwtService } from "./jwt.service";
import { Store } from "@ngrx/store";
import { setCurrentUser } from "../../ngrx/actions/user.actions";
import { UserState } from "../../ngrx/reducers/user.reducer";
import { UserService } from "../user/user.service";
import { IUser } from "../../models/user/user.model";
import { Router } from "@angular/router";
import { ExamService } from "../exam/exam.service";
import { ExamState } from "../../ngrx/reducers/exam.reducer";
import { ILevel } from "../../models/user/level.model";
import { setLevels } from "../../ngrx/actions/level.actions";
import { ThemeService } from "../theme.service";
import { ApplicationSettingService } from "../application/application-setting.service";
import { IApplicationSetting } from "../../models/application/application-setting.model";
import { setApplicationSettings } from "../../ngrx/actions/application-setting.actions";
import { AppAbilityService } from "./app-ability.service";
import { PeriodPlanService } from "../payment/period-plan.service";


@Injectable()
export class AuthService {

  private apiPrefix = '/client/authentication';

  constructor(
    private api: ApiService,
    private jwtService: JwtService,
    private store: Store<UserState | ExamState>,
    private userService: UserService,
    private examService: ExamService,
    private themeService: ThemeService,
    private applicationSettingService: ApplicationSettingService,
    private router: Router,
    private periodPlanService: PeriodPlanService,
    private abilityService: AppAbilityService,
  ) {
  }

  login(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/login`, data);
  }

  register(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/register`, data);
  }

  forgotPassword(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/forgot-password`, data);
  }

  resetPassword(requestId: string, data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/forgot-password/${requestId}`, data);
  }

  changePassword(data: object): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/password`, data);
  }

  loadUserInfo() {
    const token = this.jwtService.getToken();
    if ((!token) || (token.length < 3)) {
      return Promise.resolve().then(r => {
        this.periodPlanService.removeSelectedPeriodId();
        this.jwtService.destroyToken();
      });
    }

    return this.me().toPromise().then(r => {
      const user = r.data as IUser;
      this.store.dispatch(setCurrentUser(user));

      if (user?.userInfo?.level?.key) {
        this.themeService.current = user.userInfo.level.key;
      }

      this.loadAppRequirements(user);

      if (!this.userService.userProfileIsCompleted(user)) {
        this.router.navigateByUrl('/onboarding');
      }
    }).catch(error => {
      this.periodPlanService.removeSelectedPeriodId();
      this.jwtService.destroyToken();
    })
  }

  logout() {
    this.periodPlanService.removeSelectedPeriodId();
    this.jwtService.destroyToken();
    this.router.navigateByUrl('/auth/login');
  }

  loadAppRequirements(user: IUser) {
    this.abilityService.updateAbilities(user);
    // this.periodPlanService.setSelectedPeriodId(user.userInfo?.periodId);
    this.periodPlanService.setAutoSelectedPeriod();
    this.examService.loadExams();

    this.examService.getLevels().pipe(take(1))
      .subscribe(r => {
        const levels = r.data as ILevel[];
        this.store.dispatch(setLevels(levels))
      });

    this.applicationSettingService.getSettings().pipe(take(1))
      .subscribe(r => {
        const settings = r.data as IApplicationSetting[];
        this.store.dispatch(setApplicationSettings(settings));
      });

  }

  me(qs = '') {
    return this.api.get(`${this.apiPrefix}/me${qs}`);
  }

  validateEmail(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/validate-email`, data);
  }

  resendEmailValidation() {
    return this.api.post(`${this.apiPrefix}/resend-validation-email`, {});
  }
}
