import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { take, takeUntil } from "rxjs";
import { StorageService } from "../storage.service";
import { IPeriodPaymentPlanInfo } from "../../models/payment/period-payment-plan-info.model";

@Injectable()
export class PeriodPlanService {
  private apiPrefix = '/client/period-plan';

  constructor(
    private api: ApiService,
    private storageService: StorageService
  ) {
  }

  getListedPeriodAndPrices() {
    return this.api.get(`${this.apiPrefix}`);
  }

  getSelectedPeriodId() {
    const selectedPeriodId = this.storageService.getItem('selectedPeriodId', false);
    if (selectedPeriodId) {
      return Number(selectedPeriodId);
    }

    return undefined;
  }

  setSelectedPeriodId(val: any) {
    this.storageService.setItem('selectedPeriodId', val, false);
  }

  removeSelectedPeriodId() {
    this.storageService.removeItem('selectedPeriodId');
  }

  setAutoSelectedPeriod() {
    this.getListedPeriodAndPrices().pipe(take(1))
      .subscribe(r => {
        const periodPriceInfo = r.data as IPeriodPaymentPlanInfo;
        const selectedPeriod = this.getSelectedPeriodId();
        if (!(selectedPeriod && periodPriceInfo.userPeriodIds.includes(selectedPeriod))) {
          let selectPeriodId;

          if (periodPriceInfo.userPeriodIds.length === 1) {
            selectPeriodId = periodPriceInfo.userPeriodIds[0];
          }

          if (!selectPeriodId) {
            const activePeriod = periodPriceInfo.userPeriodIds.find(a => a == periodPriceInfo.activePeriod.id);
            if (activePeriod) {
              selectPeriodId = activePeriod;
            }
          }

          if (!selectPeriodId) {
            selectPeriodId = periodPriceInfo.userPeriodIds[periodPriceInfo.userPeriodIds.length - 1];
          }

          this.setSelectedPeriodId(selectPeriodId);
        }

      });
  }
}
